var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center",
      },
      [
        _c("img", {
          staticClass: "mx-auto mb-4",
          attrs: {
            src: require("@/assets/images/pages/not-authorized.png"),
            alt: "graphic-not-authorized",
          },
        }),
        _c(
          "h1",
          {
            staticClass:
              "sm:mx-0 mx-4 sm:mb-12 mb-8 text-5xl d-theme-heading-color",
          },
          [_vm._v(_vm._s(_vm.$t("vue.notAuthorized")))]
        ),
        _c(
          "vs-button",
          { attrs: { size: "large" }, on: { click: _vm.backToHome } },
          [_vm._v(_vm._s(_vm.$t("vue.backToHome")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }